<script lang="ts" setup>
import { useOnline } from '@vueuse/core'
const online = useOnline()
const username = ref('')
const role = ref('')
const org = ref('')

const runtimeConfig = useRuntimeConfig()

const data = useWebsiteStore()
if (data !== null) {
  username.value = data.user.username || ''
  role.value = data.user.role.role || ''
  org.value = data.user.org.org_name || ''
}
</script>

<template>
  <v-system-bar
    class="cursor-none bg-grey-darken-4"
  >
    <v-spacer />
    <span class="text-slate-500">{{ org?.toLowerCase() }}</span>
    <span class="mx-2 text-slate-500">|</span>
    <span class="text-slate-500">{{ role?.toLowerCase() }}</span>
    <span class="mx-2 text-slate-500">|</span>
    <span class="text-slate-500">{{ username?.toLowerCase() }}</span>
    <span class="mx-2 text-slate-500">|</span>
    <span :class="{ 'text-green-500': online, 'text-red-600': !online }">{{
      online ? "online" : "offline"
    }}</span>
    <span class="mx-2 text-slate-500">|</span>
    <span class="text-slate-500">{{ runtimeConfig.public.mode.toLowerCase() }}</span>
    <span class="mx-2 text-slate-500">|</span>
    <span class="text-slate-500">{{ runtimeConfig.public.version }}</span>
  </v-system-bar>
</template>

<style scoped></style>
