<script lang="ts" setup>
import { useStorage } from '@vueuse/core'

const logout = async () => {
  const website = useWebsiteStore()
  const contract = useContractStore()

  website.$reset()
  contract.$reset()

  const session = useStorage('eoiot_session', null)
  const user = useStorage('eoiot_user', null)

  session.value = null
  user.value = null
  await navigateTo('/')
}

</script>

<template>
  <v-btn
    icon="mdi-logout"
    to="/"
    class=""
    variant="plain"
    block
    @click="logout"
  />
</template>

<style scoped></style>
